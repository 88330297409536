import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi, I am Simone. For the last 25 years I have been professionally involved in the art and design scene. After my product design study at the Bauhaus University, I've worked with the prestigious Droog Design collective and with Nils Holger Moormann. Later on I worked in the production and installation of fine arts projects with Atelier van Lieshout. Together we have created an impressive range of works to be proud of. `}</p>
    <img src="./reset_your_mind_now.jpg" style={{
      "maxWidth": "100%"
    }}></img>
    <p>{`Our modern world is full of man made items, though. And too many come with a large ecological footprint. As a consumer, but also as a designer, I wish to carry on in a more sustainable manner. I believe that change can start out small: with individual awareness, with making good choices, and with deliberate action.`}</p>
    <img src="./reset_your_mind_3.jpg" style={{
      "maxWidth": "100%"
    }}></img>
    <p>{`Check out what I do, imagine what I can do for you, and get in touch via `}<a href="mailto:simone.romanow@gmail.com" style={{
        "fontSize": "1.25rem"
      }}>{`simoneromanow`}{`[at]`}{`gmail.com`}</a>{` or `}<a href="https://www.instagram.com/simone_romanow/" target="_blank" style={{
        "fontSize": "1.25rem"
      }}>{`Instagram`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      